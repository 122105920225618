import React, { useEffect, useRef } from 'react';
import './CountdownTimer.css';
import { initializeClock, getTimeRemaining } from './CountdownLogic';

const CountdownTimer = ({ handleUnmountUseState} ) => {
  const daysRef = useRef(null);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);

  useEffect(() => {
    const countdown = new Date('2023-12-23T20:10:00');
    initializeClock(countdown, { daysRef, hoursRef, minutesRef, secondsRef }, handleUnmountUseState);
  }, []);

  return (
    <div className='countdown'>
      <h1 className='bfd-announcement'>BFD Wednesday Soon</h1>
      <div className='cards'>
        <div className='card days'>
          <div className='flip-card' ref={daysRef}>
            <div className='top-half'>00</div>
            <div className='bottom-half'>00</div>
          </div>
          <p>Days</p>
        </div>
        <div className='card hours'>
          <div className='flip-card' ref={hoursRef}>
            <div className='top-half'>00</div>
            <div className='bottom-half'>00</div>
          </div>
          <p>Hours</p>
        </div>
        <div className='card minutes'>
          <div className='flip-card' ref={minutesRef}>
            <div className='top-half'>00</div>
            <div className='bottom-half'>00</div>
          </div>
          <p>Minutes</p>
        </div>
        <div className='card seconds'>
          <div className='flip-card' ref={secondsRef}>
            <div className='top-half'>00</div>
            <div className='bottom-half'>00</div>
          </div>
          <p>Seconds</p>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;