var audioPlayed = false;
export function getTimeRemaining(countdown) {
  const now = new Date();
  const diff = countdown - now;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  if (
    days == 0 &&
    hours == 0 &&
    minutes == 0 &&
    seconds == 43 &&
    !audioPlayed
  ) {
    let audio = new Audio("./breakfast.mp3");
    audio.play();
    audioPlayed = true;
  }

  return {
    diff,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function initializeClock(
  countdown,
  { daysRef, hoursRef, minutesRef, secondsRef },
  handleUnmountUseState
) {
  function updateClock() {
    const t = getTimeRemaining(countdown);
    addFlip(daysRef, t.days);
    addFlip(hoursRef, t.hours);
    addFlip(minutesRef, t.minutes);
    addFlip(secondsRef, t.seconds);

    if (
      t.days == 0 &&
      t.hours == 0 &&
      t.minutes == 0 &&
      (t.seconds == 0 || t.seconds == 1)
    ) {
      clearInterval(timeinterval);
      handleUnmountUseState();
    }
  }

  const timeinterval = setInterval(updateClock, 1000);
  updateClock();
}

function addFlip(cardRef, time) {
  const topHalf = cardRef.current.querySelector(".top-half");
  const bottomHalf = cardRef.current.querySelector(".bottom-half");

  const currentValue = topHalf.innerText;
  const newValue = time <= 9 ? `0${time}` : time;

  if (currentValue == newValue) return;

  const topFlip = document.createElement("div");
  const bottomFlip = document.createElement("div");

  topFlip.classList.add("top-flip");
  topFlip.innerText = currentValue;

  bottomFlip.classList.add("bottom-flip");
  bottomFlip.innerText = newValue;

  topFlip.addEventListener("animationstart", () => {
    topHalf.innerText = newValue;
  });

  topFlip.addEventListener("animationend", () => {
    topFlip.remove();
    bottomFlip.innerText = newValue;
  });

  bottomFlip.addEventListener("animationend", () => {
    bottomFlip.remove();
    bottomHalf.innerText = newValue;
  });

  cardRef.current.appendChild(topFlip);
  cardRef.current.appendChild(bottomFlip);
}

export default addFlip;
