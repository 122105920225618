import React, { useState } from "react";
import "./App.css";
import "./components/CountdownTimer";
import CountdownTimer from "./components/CountdownTimer";

function App() {
  const [isMounted, setIsMounted] = useState(true);

  const handleUnmountUseState = () => {
    setIsMounted(false);
  };

  return (
    <div className="App">
      {isMounted && (
        <CountdownTimer handleUnmountUseState={handleUnmountUseState} />
      )}
      {!isMounted && (
        <div className="breakfast-div">
          <h1 className="breakfast-title">Welcome to breakfast</h1>
          <img
            className="breakfast-pic"
            src="./breakfast-pic.jpg"
            alt="breakfast pic"
          />
        </div>
      )}
    </div>
  );
}

export default App;
