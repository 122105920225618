// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 14px;
  font-family: "Red Hat Text", sans-serif;
}
body {
  font-weight: 700;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: url(https://raw.githubusercontent.com/alleycaaat/frontend-mentor/main/countdown-timer/images/bg-stars.svg), linear-gradient(0deg, rgba(37, 29, 44, 1) 33%, rgba(30, 30, 40, 1) 50%);
}
h1 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: 5px;
}
main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.breakfast-div {
  display: flex;
  flex-direction: column;
}

.breakfast-title {
  font-size: 40px;
  margin: auto;
  margin-bottom: 25px;
}

.breakfast-pic {
  height: 70vh;
  width: auto;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,uCAAuC;AACzC;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,+LAA+L;AACjM;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\nhtml {\n  font-size: 14px;\n  font-family: \"Red Hat Text\", sans-serif;\n}\nbody {\n  font-weight: 700;\n  height: 100%;\n  min-height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  background: url(https://raw.githubusercontent.com/alleycaaat/frontend-mentor/main/countdown-timer/images/bg-stars.svg), linear-gradient(0deg, rgba(37, 29, 44, 1) 33%, rgba(30, 30, 40, 1) 50%);\n}\nh1 {\n  text-transform: uppercase;\n  color: #fff;\n  text-align: center;\n  margin-bottom: 50px;\n  letter-spacing: 5px;\n}\nmain {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n}\n\n.breakfast-div {\n  display: flex;\n  flex-direction: column;\n}\n\n.breakfast-title {\n  font-size: 40px;\n  margin: auto;\n  margin-bottom: 25px;\n}\n\n.breakfast-pic {\n  height: 70vh;\n  width: auto;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
